<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'no-scroll': isAppPage },
    ]"
  >
    <!-- <vx-tour :steps="steps" v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')" /> -->

    <!-- <the-customizer
      v-if="!disableCustomizer"
      :footerType="footerType"
      :hideScrollToTop="hideScrollToTop"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :routerTransition="routerTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
      @updateFooter="updateFooter"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateRouterTransition="updateRouterTransition"
    />-->

    <v-nav-menu
      :navMenuItems="editedNavMenuItems"
      title="Fresh Clinic"
      parent=".layout--main"
      dashboardRoute="/nurse/notice-board"
    />

    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay" />
      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType="navbarType"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
          :navMenuItems="navMenuItems"
        />

      </template>

      <template v-else>
        <the-nurse-navbar-vertical
          :navbarColor="navbarColor"
          :sidebar="iframe.sidebar"
          :showUserDropdown="iframe.userDropdown"
          :showCloseCta="iframe.closeCta"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">
        <div class="router-view">
          <div class="router-content">
            <transition :name="routerTransition">
              <div
                v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
                class="router-header flex flex-wrap items-center mb-6  md:block hidden"
              >
                <!-- <div
                  class="content-area__heading"
                  :class="{
                    'pr-4 border-0 md:border-r border-solid border-grey-light':
                      $route.meta.breadcrumb
                  }"
                >
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div> -->

                <!-- BREADCRUMB -->
                <vx-breadcrumb
                  class="ml-4"
                  v-if="$route.meta.breadcrumb"
                  :route="$route"
                  :isRTL="$vs.rtl"
                />
              </div>
            </transition>

            <div class="content-area__content">
              <back-to-top
                bottom="5%"
                :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                visibleoffset="500"
                v-if="!hideScrollToTop"
              >
                <vs-button
                  icon-pack="feather"
                  icon="icon-arrow-up"
                  class="shadow-lg btn-back-to-top"
                />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view
                  @changeRouteTitle="changeRouteTitle"
                  @setAppClasses="
                    (classesStr) => $emit('setAppClasses', classesStr)
                  "
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import navMenuItems from "@/layouts/components/vertical-nav-menu/nurse/NurseNavMenuItems.js";
import nursePermissionMenuItems from "@/layouts/components/vertical-nav-menu/nurse/NursePermissionMenuItems.js";
// import TheCustomizer from "@/layouts/components/customizer/TheCustomizer.vue";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNurseNavbarVertical from "@/layouts/components/navbar/nurse/TheNurseNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import LDClient from "launchdarkly-js-client-sdk";
import StaffHelper from '@/mixins/Staff';
import OrganizationHelper from '@/mixins/Organization';
import { mapActions } from "vuex";

// const VxTour = () => import("@/components/VxTour.vue");

export default {
  components: {
    BackToTop,
    HNavMenu,
    // TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNurseNavbarVertical,
    VNavMenu,
    // VxTour
  },
  mixins:[StaffHelper, OrganizationHelper],
  provide() {
    return {
      getRoleRouteName: this.getRoleRouteName
    }
  },
  data() {
    return {
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: themeConfig.navbarType || "floating",
      navMenuItems: navMenuItems,
      editedNavMenuItems: [],
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
      steps: [
        {
          target: "#btnVNavMenuMinToggler",
          content: "Toggle Collapse Sidebar.",
        },
        {
          target: ".vx-navbar__starred-pages",
          content:
            "Create your own bookmarks. You can also re-arrange them using drag & drop.",
        },
        {
          target: ".i18n-locale",
          content: "You can change language from here.",
        },
        {
          target: ".navbar-fuzzy-search",
          content: "Try fuzzy search to visit pages in flash.",
        },
        {
          target: ".customizer-btn",
          content: "Customize template based on your preference",
          params: {
            placement: "left",
          },
        },
        {
          target: ".vs-button.buy-now",
          content: "Buy this awesomeness at affordable price!",
          params: {
            placement: "top",
          },
        },
      ],
      organizationId:null,
      permissionMenuItems:nursePermissionMenuItems,
      iframe: {
        sidebar: true,
        navbar: true,
        userDropdown: true,
        footer: true,
      }
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },
  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth == "default")
          return "content-area-reduced";
        else if (this.verticalNavMenuWidth == "reduced")
          return "content-area-lg";
        else return "content-area-full";
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full";
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static",
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme == "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating",
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    ...mapActions("admin", ["fetchUserPermission", "checkPOSEnabledClinicsFromOrg"]),
    async onLaunchDarklyReady() {
      const organizationStatus = await this.getOrganizationComplianceStatus();
      const isAnalyticsEnabled = organizationStatus.isAnalyticsEnabled;
      // let defaultNavItems = _.pull(
      //   _.cloneDeep(this.navMenuItems).map((item) => {
      //     if (!item.items) {
      //       if (this.ldClient.variation(item.slug, false)) {
      //         return item;
      //       }
      //     } else {
      //       item.items = _.pull(
      //         item.items.map((subitem) => {
      //           if (this.ldClient.variation(subitem.slug, false)) {
      //             return subitem;
      //           }
      //         }),
      //         undefined
      //       );
      //       if (item.items.length) {
      //         return item;
      //       }
      //     }
      //   }),
      //   undefined
      // );

      const organizationId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
      const {data: hasPOSEnabledClinics} = await this.checkPOSEnabledClinicsFromOrg({organizationId: organizationId});

      const hideMenuUS = process.env.VUE_APP_REGION === "US" ? ["Invoice Management", "Inventory", "Drugbook"] : [];
      let permissionMenus = await this.getUserPermissions()
      permissionMenus = permissionMenus.filter((menu) => !hideMenuUS.includes(menu.name))
      /**
       * Scenario for showing POS in nav.
       * 1. If POS is enabled for a nurse user and
       * a clinic in the organization has POS enabled,
       * POS menu will show in their account.
       * 2. If POS is enabled for a nurse user but
       * there's no POS enabled clinics in the organization,
       * POS will be hidden in the nav.
       * 3. If POS is disabled for a nurse user and
       * a clinic in the organization has POS enabled,
       * POS wil be hidden in the nav.
       */
      const POSPermiIndex = permissionMenus.findIndex((menu) => menu.name === 'POS')
      if (POSPermiIndex !== -1 && !hasPOSEnabledClinics.data) {
        permissionMenus.splice(POSPermiIndex, 1)
      }

      this.editedNavMenuItems = _.pull(
        _.cloneDeep(permissionMenus).map((item) => {
          if (item.name === "nurse-dashboard" && !isAnalyticsEnabled){
              return;
          }

          return item;
        }),
        undefined
      );
      // this.editedNavMenuItems = [...defaultNavItems, ...permissionMenus]
    },
    filterNurseLinks() {

    },
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val == "static")
        this.updateNavbarColor(this.isThemeDark ? "#10163a" : "#fff");
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == "#fff") this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
  },
  beforeMount: async function () {
    // const userId = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`))._id;
    // const organizationId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
    // const hide = ["POS", "Dashboard", "Events", "Resources", "Consent Forms"];
    // const hideMenuNZ = ["POS","Events", "Financial Assistance", "Insights"];
    // const {data: hasPOSEnabledClinics} = await this.checkPOSEnabledClinicsFromOrg({organizationId: organizationId});
    // const { data: permission } = await this.fetchUserPermission({
    //     userId: userId,
    //     organizationId: organizationId,
    //   })
    // this.navMenuItems = _.pull(
    //   _.cloneDeep(navMenuItems).map((item) => {
    //     if (process.env.VUE_APP_REGION === "US") {
    //       const dashboardIndex = hide.indexOf("Dashboard")
    //       hide.splice(dashboardIndex, 1)
    //       if (_.indexOf(hide, item.name) === -1) {
    //         return item;
    //       }
    //       return;
    //     }
    //     if (process.env.VUE_APP_REGION === "NZ") {
    //       if (_.indexOf(hideMenuNZ, item.name) === -1) {
    //         return item;
    //       }
    //       return;
    //     }
    //     /**
    //      * Scenario for showing POS in nav.
    //      * 1. If POS is enabled for a nurse user and
    //      * a clinic in the organization has POS enabled,
    //      * POS menu will show in their account.
    //      * 2. If POS is enabled for a nurse user but
    //      * there's no POS enabled clinics in the organization,
    //      * POS will be hidden in the nav.
    //      * 3. If POS is disabled for a nurse user and
    //      * a clinic in the organization has POS enabled,
    //      * POS wil be hidden in the nav.
    //      */
    //     if (item.name === "POS"){
    //       if (_.find(permission, (permi) => permi.permission.name === 'POS') && hasPOSEnabledClinics.data) {
    //         return item;
    //       } else {
    //         return;
    //       }
    //     }
    //     return item;
    //   }),
    //   undefined
    // );

    const { badge: key, email, userRole } = this.$store.state.AppActiveUser;
    var user = {
      key: key,
      email: email,
      userRole,
    };
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
    this.ldClient.on("ready", this.onLaunchDarklyReady);
    this.ldClient.on("change", this.onLaunchDarklyReady);
  },
  created() {
    const tempIframe = JSON.parse(localStorage.getItem('iframe-view'))
    this.iframe.sidebar = tempIframe.sidebar === false ? false : true
    this.iframe.navbar = tempIframe.navbar === false ? false : true
    this.iframe.userDropdown = tempIframe.userDropdown === false ? false : true
    this.iframe.footer = tempIframe.footer === false ? false : true
    this.iframe.closeCta = tempIframe.closeCta === true ? true : false
    const color =
      this.navbarColor == "#fff" && this.isThemeDark
        ? "#10163a"
        : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);
    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      "$store.state.windowWidth",
      (val) => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      }
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      "$store.state.verticalNavMenuWidth",
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      }
    );

    this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};
</script>
