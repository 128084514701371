let menuItem = [
  {
    url: "/nurse/notice-board",
    name: "Dashboard",
    icon: "MonitorIcon",
    slug: "dashboard",
    i18n: process.env.VUE_APP_REGION === "US" ? "Notice Board" : "Dashboard",
  },
  ...process.env.VUE_APP_REGION !== "US" ? [
    {
      url: "/nurse/clinics",
      name: "Clinics Management",
      icon: "HomeIcon",
      slug: "clinics",
      i18n: "Clinics",
    },
  ] : [],
  {
    url: "/nurse/patients",
    name: "Patient Management",
    icon: "FolderPlusIcon",
    slug: "pages-register-nurse",
    i18n: "Patients",
  },
  {
    url: "/nurse/treatment-history",
    name: "Treatment History",
    icon: "ActivityIcon",
    slug: "treatment-history",
    i18n: "Treatments",
  },
  {
    url: "/nurse/events",
    name: "Events",
    icon: "CalendarIcon",
    slug: "events",
    i18n: "Events",
  },
  {
    url: "/nurse/resources",
    name: "Resources",
    icon: "CodepenIcon",
    slug: "resources",
    i18n: "Resources",
  },
  {
    url: "/nurse/financial-assistance",
    name: "Financial Assistance",
    icon: "GridIcon",
    slug: "financial-assistance",
    i18n: "Financial Assistance",
  },
  {
    url: "/nurse/consent-forms",
    name: "Consent Forms",
    icon: "ActivityIcon",
    slug: "compliance",
    i18n: "Consent Forms",
  },
  // {
  //   url: '/nurse/notifications',
  //   name: "nurseNotification",
  //   slug: "notification",
  //   i18n: "Notifications",
  //   icon: "BellIcon",
  // }
  {
    url: '/nurse/pos',
    name: "POS",
    slug: "pos",
    i18n: "POS",
    icon: "DollarSignIcon",
  }
];

if (process.env.VUE_APP_REGION !== "AU") {
  menuItem = menuItem.filter((val) => val.slug !== 'financial-assistance');
}

export default menuItem;